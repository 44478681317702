import { render, hydrate, unmountComponentAtNode } from "react-dom";
import { ReactElement, createElement, FunctionComponent, ComponentClass, Attributes, createRef } from "react";


/**
 * Bind a react component to the DOM with it's given props.
 * @param id "Unique ID"
 * @param reactElem  "Functional or Class Component"
 * @param props "Props"
 * @returns 
 */
export function bindComponent(id : string | HTMLElement, reactElem : FunctionComponent<{}> | ComponentClass<{}, any>
  ,props?: Object | Attributes | null | undefined ) : ReactElement | null {
  let elem : HTMLElement | null;
  if (id instanceof HTMLElement) {
    elem = id;
  } else {
    elem = document.getElementById(id);
  }
  if (elem) {
    //unmountComponentAtNode(elem);
    var obj : any = createElement(reactElem, props, null);
    
    render(obj, elem, () => {
      //console.log("Rendered", elem);
    });
    return obj;
  } else {
    console.error(`Element with id ${id} not found`);
  }
  return null;
}

export {
  createElement,
  createRef
}

export function cleanComponent(reactElem : any) : boolean {
  try {
    return unmountComponentAtNode(reactElem);
  } catch (e) {
    return false;
  }
}

export function MakeEmptyRef<T>() {
  return createRef<T>();
}

/**
 * Render a React Component to the DOM. 
 * It works with a unique instance ID. 
 * @param pUID Unique ID, can be dom ID
 * @param pSrc Source module file
 * @param pExportName Component export name
 * @param pIncCSS Must include CSS of this component.
 * @returns A Promise is returned when the module has been completely loaded.
 */
export function reactDynamicComponent(pUID : string,pSrc : string, pExportName: string
    , pIncCSS : boolean | undefined) : Promise<any> {
  //console.log("importComponent",pSrc);
  const importStr = pExportName.includes("@") ? pExportName.replace("@","") : `{${pExportName}}`;
  const importName = pExportName.includes("@") ? pExportName.replace("@","") : `${pExportName}`;

  return new Promise<any>((resolve, reject) => {
    try {
      let wnd : any = window;
      let cssSrc = pSrc.replace(".js", ".css");
      let srcObj = document.head.querySelector(`#react_mod_${pUID}`);
      wnd._react_dynamic = wnd._react_dynamic || {}; 
      if (!srcObj) {
          let srcObj = document.createElement("script");
          srcObj.type = "module";
          srcObj.id = `react_mod_${pUID}`;
          srcObj.text = `
          import { bindComponent, cleanComponent } from "/res/dist/powerbidsrc/bind_react.js";
          import  ${importStr}  from "${pSrc}";

          window._react_dynamic["${pUID}"] = function(pElemID,pParms) {
            if (typeof pElemID != "string") {
              pElemID = '${pUID}';
            }
            let obj = document.getElementById(pElemID);
      
            if (!obj) {
                obj = document.createElement("div");
                obj.id = pElemID;
                document.body.appendChild(obj);
            }
            
            let link = document.getElementById("react_css_${importName}");
            if (!link && ${pIncCSS} == true) {
                obj.setAttribute("loadcss","true");
                let link = document.createElement("link");
                link.id = "react_css_${importName}";
                link.rel = "stylesheet";
                link.type = "text/css";
                link.setAttribute("component_css","1");
                link.href = "${cssSrc}";
                link.onload = (e) => {
                    obj.setAttribute("css","loaded");
                }
                document.head.appendChild(link);

                waitTill(function()  {
                  return obj && obj.hasAttribute("css")
                }, function()  {      
                        cleanComponent(obj);    
                        bindComponent(obj,${importName},pParms ); 
                }, function() {
                    console.log("Failed to load valid css for react obj");
                    cleanComponent(obj);    
                    bindComponent(obj,${importName},pParms ); 
                },5000);
            } else {
              cleanComponent(obj);    
              bindComponent(obj,${importName},pParms );  
            }
        
          };`;
          //console.log("srcObj",srcObj.innerText)
          document.head.appendChild(srcObj);
          
      }
      
      if (wnd._react_dynamic && wnd._react_dynamic[pUID]) {
        resolve(wnd._react_dynamic[pUID]);
      } else {
        let cnt = 0;
        let itv = setInterval(() => {
          cnt++;
          if (wnd._react_dynamic && wnd._react_dynamic[pUID]) {
            clearInterval(itv);
            resolve(wnd._react_dynamic[pUID]);
          }
          if (cnt > 100) {
            clearInterval(itv);
            reject(`Failed to load valid react module ${pUID} in ${pSrc}"`);
          }
        },100);
      }

    } catch (e) {
      reject(e);    
    }
  });
}